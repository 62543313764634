import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const VideosPage = () => (
  <Layout>
    <SEO title="Videos" />
    <div className="container" style={{marginTop:'80px'}}>
      <div className="row">
        <div className="col-md-6">
          <div style={{backgroundColor: 'rgba(0, 0, 0, 0.87)', borderRadius: '10px', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)', padding: '25px', marginBottom: '10px'}}>
            <h4 style={{color: '#F7D247', marginBottom: '25px', opacity: '0.8'}}>Sex Work in the City - A Documentary</h4>
            <p style={{fontSize: '15px', color: 'white', marginBottom: '20px'}}>A documentary about the sex work industry during Covid-19</p>
            <Link to="/videos/video-one" style={{textDecoration: 'none'}}><button style={{borderRadius: '3px', border: 'none', backgroundColor: '#F7D247', fontSize: '15px', padding: '5px 10px 5px 10px', fontWeight: 'bold', color: "#000000"}}>Watch Video</button></Link>
          </div>
        </div>

        <div className="col-md-6">
          <div style={{backgroundColor: 'rgba(0, 0, 0, 0.87)', borderRadius: '10px', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)', padding: '25px', marginBottom: '10px'}}>
            <h4 style={{color: '#F7D247', marginBottom: '25px', opacity: '0.8'}}>Greco Fitness Promo Video</h4>
            <p style={{fontSize: '15px', color: 'white', marginBottom: '20px'}}>Promotional advertisement created for a local gym</p>
            <Link to="/videos/video-two" style={{textDecoration: 'none'}}><button style={{borderRadius: '3px', border: 'none', backgroundColor: '#F7D247', fontSize: '15px', padding: '5px 10px 5px 10px', fontWeight: 'bold', color: "#000000"}}>Watch Video</button></Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div style={{backgroundColor: 'rgba(0, 0, 0, 0.87)', borderRadius: '10px', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)', padding: '25px', marginBottom: '10px'}}>
            <h4 style={{color: '#F7D247', marginBottom: '25px', opacity: '0.8'}}>Wall St. Maker Product Ad #1</h4>
            <p style={{fontSize: '15px', color: 'white', marginBottom: '20px'}}>Promotional advertisement created for an Amazon client</p>
            <Link to="/videos/video-three" style={{textDecoration: 'none'}}><button style={{borderRadius: '3px', border: 'none', backgroundColor: '#F7D247', fontSize: '15px', padding: '5px 10px 5px 10px', fontWeight: 'bold', color: "#000000"}}>Watch Video</button></Link>
          </div>
        </div>
        <div className="col-md-6">
          <div style={{backgroundColor: 'rgba(0, 0, 0, 0.87)', borderRadius: '10px', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)', padding: '25px', marginBottom: '10px'}}>
            <h4 style={{color: '#F7D247', marginBottom: '25px', opacity: '0.8'}}>Wall St. Maker Product Ad #2</h4>
            <p style={{fontSize: '15px', color: 'white', marginBottom: '20px'}}>Promotional advertisement created for an Amazon client</p>
            <Link to="/videos/video-four" style={{textDecoration: 'none'}}><button style={{borderRadius: '3px', border: 'none', backgroundColor: '#F7D247', fontSize: '15px', padding: '5px 10px 5px 10px', fontWeight: 'bold', color: "#000000"}}>Watch Video</button></Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div style={{backgroundColor: 'rgba(0, 0, 0, 0.87)', borderRadius: '10px', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)', padding: '25px', marginBottom: '10px'}}>
            <h4 style={{color: '#F7D247', marginBottom: '25px', opacity: '0.8'}}>Valiant Home Product Ad</h4>
            <p style={{fontSize: '15px', color: 'white', marginBottom: '20px'}}>Promotional advertisement created for an Amazon client</p>
            <Link to="/videos/video-five" style={{textDecoration: 'none'}}><button style={{borderRadius: '3px', border: 'none', backgroundColor: '#F7D247', fontSize: '15px', padding: '5px 10px 5px 10px', fontWeight: 'bold', color: "#000000"}}>Watch Video</button></Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default VideosPage
